//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


  .studentInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin-left: 20px;
    & > * {
      font-size: 13px;
      color: #4a4a4a;
      margin-right: 20px;
      span {
        font-weight: bold;
      }
    }
  }