//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.ExportDropdown {
  position: relative;
  cursor: pointer;
  .opener {
    width: 75px;
    height: 40px;
    background-color: white;
    .text {
      display: flex;
      align-items: center;
      padding-left: 12px;
      width: calc(100% - 30px);
      height: 100%;
      font-size: 13px;
      color: #292929;
    }
    .icon {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 100%;
    }
  }
  .options {
    position: absolute;
    right: 0;
    top: 50px;
    width: 147px;
    background: #f1efee;
    box-shadow: 0px 2px 23px #e4e6e9;
    z-index: 2;
    .option {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 12px;
      font-size: 13px;
      color: #4a4a4a;
      background-color: white;
      margin-bottom: 1px;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        background-color: #f5f4f3;
      }
    }
  }
}
