//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.wrapper {
  position: relative;
  min-height: calc(100vh - 280px);
  .tablePushBar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    // padding: 10px;
    height: 60px;
    background-color: white;
    border: 1px solid #D8D8D8;
    //margin-top: 5px;
    // margin: 10px;
    p{
      color: #9B9B9B;
      font-size: 13px;
      margin: 20px;
    }

    .pushBtn{
      display: flex;
      color: #292929;
      font-size: 13px;
      background: #F1EFEE;
      width: 300px;
      height: 40px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 1px solid #D8D8D8;
    }

    .tableBarLeft {
      flex: 1 1;
      display: flex;
      align-items: center;
      width: 50%;
      border-right: 1px solid #D8D8D8;
      // margin: 0 -10px;
      & > * {
        margin: 0 10px;
      }

    }
    .tableBarRight {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin: 0 -10px;

      & > * {
        margin-right: 10px;
      }
    }
  }
}