//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.wrapper {
  display: flex;
  flex-direction: column;
  font-size: 0;
  & > * {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .title {
    display: flex;
    align-items: center;
    height: 42px;
    & > *:first-child {
      margin-right: 20px;
    }
  }
  .newAttachment {
    display: flex;
    flex-direction: column;
    width: 900px;
    margin-bottom: 10px;
    .inputs {
      display: flex;
      justify-content: space-between;
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      margin: 45px 0 20px 0;
      .buttonSecondary {
        width: 80px;
        height: 30px;
        font-size: 13px;
        background: #ffffff;
        border: 1px solid #d8d8d8;
        color: #292929;
      }
      .buttonMain {
        width: 80px;
        height: 30px;
        color: white;
        background-color: #4a4a4a;
        border: none;
        font-size: 13px;
      }
    }
  }
  .attachments {
    display: flex;
    flex-direction: column;
    .attachment+.attachment{
      margin-top: 10px;
    }
    .attachment {
      width: 900px;
      background-color: #f5f5f5;
      font-size: 13px;

      .main {
        display: flex;
        .left {
          width: 60px;
          min-width: 60px;
          padding: 10px;
          .photo {
            width: 40px;
            height: 40px;
            background-color: #ebebeb;
            border-radius: 50%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
        .right {
          flex: 1 1;
          padding: 10px;
          & > * {
            margin-bottom: 10px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          .author {
            display: flex;
            justify-content: space-between;
            color: #9b9b9b;
            .edit_button {
              cursor: pointer;
              color: $additional-color
            }
          }
          .link {
            display: inline-block;
            max-width: 200px;
            text-decoration: underline;
            color: #292929;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .description {
            color: #292929;
            white-space: pre-wrap;
          }
        }
      }
      .buttons {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        .delete {
          color: $negative-color;
          background: transparent;
          border: none;
        }
      }
    }
  }
  .message {
    display: flex;
    align-items: center;
    height: 42px;
    font-size: 13px;
    color: #9b9b9b;
  }
}


.historyOfChangesBtn {
	cursor: pointer;
	text-decoration: underline;
}

.historyOfChanges {
	position: relative;
	margin-right: auto;
}

.infoTitle{
  display: flex;
  
  p+p{
		margin-left:2px;
	}
}

.wrapperBtn{
	button{
		margin-left: 360px;
	}
}