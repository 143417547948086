//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.page {
  margin: 265px 30px 10px;
  .actions {
    display: grid;
    grid-row-gap: 10px;
    justify-content: center;
    margin-top: 20px;
  }
}

.searchField {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 20px;
  label {
    margin-right: 10px;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 20px;
  min-height: 400px;
  .column {
    height: 100%;
    padding: 0 15px;

    h5 {
      margin: 5px 0;
    }
    .item {
      display: inline-flex;
      margin: 4px;
      padding: 8px 14px;
      border-radius: 4px;
      cursor: pointer;
      img {
        margin-left: 10px;
        cursor: pointer;
      }

      &_tick {
        width: 10px;
      }

      &_word {
        display: flex;
      }

      .match_value {
        color: $additional-color;
      }
    }

    .active_item {
      color: #4a4a4a;
      background: #e6e6e6;
      border-radius: 4px;
    }

    .removed_item {
      color: #9b9b9b;
      border: 1px dashed $additional-color;
    }

    &:nth-child(1) {
      border-right: 1px solid black;
    };
  }

}
