//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.HeaderNavLinks {
  display: flex;
  justify-content: space-between;
  width: 590px;
  height: 50px;
  a {
    display: flex;
    align-items: center;
    height: 100%;
    color: #4A4A4A;
    font-size: 15px;
    text-transform: uppercase;
    padding: 0 10px;
    &.active {
      font-weight: bold;
      border-top: 3px solid transparent;
      border-bottom: 3px solid #4A4A4A;
    }
  }
}
