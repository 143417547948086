//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.ActionsDropdown {
  position: relative;

  .button {
    width: 100px;
    height: 40px;
    font-size: 13px;
    text-transform: uppercase;
    color: #292929;
    background-color: white;
    border: none;
  }
  .actions {
    position: absolute;
    right: -10px;
    top: 50px;
    min-width: 120px;
    background-color: #f1efee;
    box-shadow: 0px 2px 23px #e4e6e9;
    z-index: 2;

    .action {
      display: flex;
      align-items: center;
      height: 40px;
      background-color: white;
      margin-bottom: 1px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        background-color: #f5f4f3;
      }
      .icon {
        width: 40px;
        text-align: center;
      }
      .text {
        color: #4a4a4a;
        font-size: 13px;
        white-space: nowrap;
        padding: 0 10px;
      }
    }
  }
}
