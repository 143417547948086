//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.wrapper {
  background-color: white;
  border: 1px solid #d8d8d8;
}
.backButtonWrapper {
}
.main {
  display: flex;
  align-items: stretch;
  & > * {
    flex: 0 0;
  }
  & > *:last-child {
    flex: 1 1;
  }
}
.line {
  align-self: stretch;
  border-left: 1px solid #d8d8d8;
}

.actionsWrapper {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 10px;
  border-bottom: 1px solid #d8d8d8;
}

.additionWrapper {
  margin-left: 50px;
  font-size: $big-size;
  font-weight: 700;
  cursor: pointer;
  border-bottom: 1px solid $black-color ;
}


