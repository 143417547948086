//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.header {
  display: flex;
  align-items: center;
  height: 52px;
}
.content {
  display: flex;
  flex-wrap: wrap;
  .block {
    margin: 10px 50px 10px 0;
    display: flex;
    align-items: center;
    width: 500px;
    height: 38px;
    .text {
      width: 330px;
      height: 100%;
      padding: 0 10px;
      color: #4a4a4a;
      font-size: 13px;
      line-height: 38px;
      background-color: #f5f5f5;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .button {
      width: 50px;
      height: 100%;
      border: none;
      &.primary {
        color: $white-color;
        background-color: #4a4a4a;
      }
      &.secondary {
        background-color: #f5f5f5;
        text-decoration: underline;
      }
    }

    .edit {
      color: $additional-color!important;
    }

    .delete {
      color: $negative-color;
    }
  }

  .item {
    position: relative;
    .error {
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
