//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.wrapper {
  padding: 40px;
  min-height: 580px;
  height: 100%;
}

.edit_wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  position: sticky;
  top: 55px;
  z-index: 25;
}

.titleWrapper {
  display: grid;
  grid-template-columns: max-content 1fr;
  justify-content: space-between;
  align-items: center;
  height: 42px;
}

.filters {
  display: flex;
  align-items: center;
  height: 50px;
  .filter {
    margin-right: 10px;
    cursor: pointer;
    color: #9b9b9b;
    font-size: 15px;
    &.active {
      color: #4a4a4a;
    }
  }
}
.contentWrapper {
  padding-top: 22px;
  display: flex;
  justify-content: space-between;
}

.alertSign {
  color: #d0021b;
}
.alertMeaning {
  color: #9b9b9b;
}
.subforms {
  width: 900px;
  min-width: 900px;
  & > * {
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.snapshots {
  margin-left: 40px;
  & > * {
    margin-bottom: 10px;
  }
  .title {
    color: #4a4a4a;
  }
  .snapshot {
    color: #9b9b9b;
    cursor: pointer;
    &.active {
      color: #d0021b;
    }
  }
}
