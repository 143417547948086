//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.FormWrapper {
	display: flex;
	flex-direction: column;

	background-color: white;

	.titleWrapper {
		display: grid;
		align-items: center;
		height: 50px;
		margin-bottom: 30px;
	}

	.wrapper {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.main {
		flex: 1 1;
		position: relative;
		padding: 40px;
		.spinnerWrapper {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-color: rgba(74, 74, 74, 0.3);
			z-index: 10;

			&.fixedSpinner {
				position: fixed;
				z-index: 1000;
			}
		}
	}

	.footer {
		display: grid;
		align-items: center;
		padding: 0 40px;
		background-color: #fafafa;
		justify-content: center;
		padding: 15px 0;
		button {
			font-size: 13px;
			min-width: 115px;
			height: 40px;
		}
		.cancel {
			left: 40px;
			background: #ffffff;
			border: 1px solid #d8d8d8;
			text-transform: uppercase;
			color: #292929;
		}
		.delete {
			color: $negative-color;
			background: transparent;
			border: none;
			&::first-letter {
				text-transform: uppercase;
			}
		}
		.save {
			right: 40px;
			border: none;
			text-transform: uppercase;
			color: white;
			background-color: #4a4a4a;
		}
	}
}
