//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.wrapper{
	position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
	z-index: 400;
}
.loadMoreBtn {
	display: block;
	background-color: #f1efee;
	border: 2px solid #d8d8d8;
	color: #9b9b9b;
	padding: 5px 10px;
	border-radius: 5px;
	margin-top: 5px;
}

.historyOfChangesModal {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 600px;
	height: 290px;
	background-color: #ffffff;
	border: 1px solid #d8d8d8;
	padding: 10px 10px;
  z-index: 500;
}

.historyOfChangesHeder{
	display: flex;
	justify-content: space-between;
}
.tittle{
	margin-left: 3px;
}

.closeHistoryOfChangesBtn{
	width: 20px;
	height: 20px;
	border: none;
	background-color: transparent;
}

.historyOfChangesList {
	display: flex;
	flex-direction: column;
	li + li {
		margin-bottom: 5px;
	}
}

.historyOfChangesItem {
	display: flex;
	padding: 10px;
	width: 560px;
	color: #292929;
	border: 2px solid #d8d8d8;
	border-radius: 5px;

	p {
		font-size: 15px;
		color: #9b9b9b;
	}

	span {
		margin-right: 3px;
	}

	h4,
	.massage {
		white-space: break-spaces;
		overflow: hidden;
	  text-overflow: ellipsis;
		color: #292929;
	}
}
.previewPhoto{
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 3px;

	width: 82px;
	height: 82px;

	margin-right: 10px;

	background-color: #f5f5f5;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.wrap_message{
	display: block;
  width: 450px;
	overflow: hidden;
	white-space: nowrap;
}
.fileName{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.name{
	font-size: 15px;
}