//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.Security{
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Tittle{
  font-size: 31px;
  margin: 5px 0;
}



.DisconectInfo{
  font-size: 22px;
}

.DisconectBtn{
  width: 260px;
  height: 50px;
  font-size: 20px;
  background-color: #fff;
  color: #ed4040;
  border: 1px solid #ed4040;
  border-radius: 6px;
  margin-top: 10px;
}


