//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.wrapper {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
	z-index: 400;
}

.modal {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 600px;
	height: 290px;
	background-color: #ffffff;
	border: 1px solid #d8d8d8;
	padding: 20px;
	z-index: 500;
}

.tittle {}

.continueBtn {
	padding: 10px 0;
	border: none;
	font-size: 25px;
	border-radius: 8px;
	text-shadow: 0 0 black;
	background-color: #EA8226;
	color: #fafafa;
}