//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


@import "./styles/_variables.scss";

@font-face {
  font-family: San-Francisco;
  src: url('./assets/fonts/SF-Pro-Display-Regular.otf');
}
@font-face {
  font-family: "Roboto";
  src: url('./assets/fonts/Roboto-Thin.ttf');
}
* {
  box-sizing: border-box;
  outline: none;
  font-family:  San-Francisco, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}


button {
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
}

input {
  padding: 0 10px;
  &:disabled {
    cursor: not-allowed;
  }
  &::-ms-clear {
    display: none;
  }
}
