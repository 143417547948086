//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.wrapper {
  width: 945px;
  margin-top: 70px;
  margin-bottom: 19px;
  background-color: white;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 2px 25px rgba(208, 201, 201, 0.5);
}

.row {
  display: grid;
  grid-template-columns: 30px 200px;
}

.header {
  display: flex;
  align-items: center;
  min-height: 38px;
  width: 100%;
  justify-content: space-between;
  padding-left: 10px;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  cursor: pointer;
}
.line {
  border-top: 1px solid #f1efee;
}
.contentText {
  padding: 10px;
  font-size: 13px;
  text-align: justify;
  color: #4a4a4a;
  word-break: break-all;
}

.title {
  color: $secondary-color;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0 20px 10px 20px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.buttons {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(2, 1fr);
  padding: 10px;
}

.left_buttons {
  display: grid;
  grid-template-columns: repeat(2, 140px);
  grid-column-gap: 9px;
}

.right_button {
  display: grid;
  justify-self: end;
}
