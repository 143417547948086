//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.TableData {
  min-height: 240px;
  padding: 10px;
  background-color: white;
  border: 1px solid #D8D8D8;
  position: relative;
  z-index: 12;
  .columnsWrapper {
    display: flex;
    width: max-content;
    scrollbar-color: $secondary-color #fafafa;
    scrollbar-width: thin !important;
    position: relative;
    z-index: 5;
    /* width */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #fafafa;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $secondary-color;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      cursor: pointer;
    }
    .column {
      position: relative;
      &:first-child .cell:nth-child(2n + 1) {
        border-left: 1px solid $additional-color;
      }
      &:last-child .cell:nth-child(2n + 1) {
        border-right: 1px solid #D8D8D8;
      }
      .headerCell {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 26px;
        border: 1px solid transparent;
        padding: 0 12px 0 10px;
        color: #4a4a4a;
        overflow: hidden;
        font-size: 13px;
        font-weight: bold;
        white-space: nowrap;
        background: #ffffff;
        .headerCellInner {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      .cell {
        position: absolute;
        display: flex;
        align-items: center;
        width: 100%;
        height: 46px;
        padding: 0 10px;
        background: white;
        font-size: 13px;
        color: #4a4a4a;
        overflow: hidden;
        white-space: nowrap;
        &:nth-child(2n + 1) {
          border-top: 1px solid #D8D8D8;
          border-bottom: 1px solid #D8D8D8;
        }
        .image {
          width: 38px;
          min-width: 38px;
          height: 38px;
          min-height: 38px;
          background-color: #ebebeb;
          border-radius: 50%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .link {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
  .spinnerWrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(74, 74, 74, 0.3);
    z-index: 5;
  }
  .error {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    color: #D0021B;
    z-index: 1;
  }

  .columnWrapper {
    display: flex;
    height: 0;
    position: absolute;
    top: 0;
    z-index: 2;
  }

  .emptyBlock{
    height: 46px;
    background: $white-color;
  }
}

.positionSticky {
  position: sticky!important;
}

.tablePositions {
  position: fixed;
  overflow-y: scroll;
  right: 0;
  left: 0;
  height: 605px;
  width: 98.9%;
  padding: 0 10px;
  margin: 0 11px;
  margin-right: 15px;
  border-right: 1px solid #D8D8D8;
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    cursor: pointer;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgb(250, 250, 250);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(74, 74, 74);
    cursor: pointer;
  }

}

