//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.notification {
	position: relative;
	.information {
		position: absolute;
		top: -35px;
		background: $white-color;
		font-size: $middle_small-size;
		padding: 10px;
		border-radius: $small-radius;
		transition: 0.4s ease-in-out;
		opacity: 0;
	  transform: scale(0);
		border: 1px solid $additional-color;
		z-index: 10000;
		&.hover {
			opacity: 1;
			transform: scale(1)
		}
	}
	.message {
		display: flex;
		align-items: center;
		background: $grey-color;
		padding: 17px 13px;
		.img {
			cursor: pointer;
		}
		.content {
			display: inline-block;
			margin-left: 8px;
		}
	}
}
