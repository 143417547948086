//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.DatePeriodDropdown {
  position: relative;

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 199px;
    .input {
      position: relative;
      width: 115px;
      height: 40px;
      background-color: white;
      border: 1px solid #d8d8d8;
      cursor: pointer;
      .value {
        display: flex;
        align-items: center;
        padding-left: 12px;
        width: calc(100% - 30px);
        height: 100%;
        font-size: 13px;
        color: #292929;
        overflow: hidden;
        white-space: nowrap;
      }
      .icon {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 100%;
      }
    }
  }

  .popup {
    position: absolute;
    left: -2px;
    top: 42px;
    padding-left: 2px;
    z-index: 1;

    .datePickerWrapper {
      position: absolute;
      left: -384px;
      top: 0;
      width: 384px;
      background-color: white;
    }

    .periods {
      width: 199px;
      overflow-y: auto;
      background-color: #f1efee;
      box-shadow: 0px 2px 23px #e4e6e9;
      .period {
        display: flex;
        align-items: center;
        height: 38px;
        background-color: white;
        margin-bottom: 1px;
        font-size: 13px;
        color: #292929;
        overflow: hidden;
        white-space: nowrap;
        padding: 0 12px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          background-color: #f5f4f3;
        }
      }
    }
  }
}
