//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.contentWrapper {
  display: flex;

  &>* {
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }


  .columnWrapper {
    display: flex;
    flex-direction: column;
    margin-top: -7px;
    align-items: flex-end;

    &>* {
      margin: 7px 0;
    }

    .latestUpdateWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      color: $additional-color;
    }

    .inputWrapper {
      position: relative;
      width: 410px;
      height: 38px;

      &>* {
        position: absolute;

        &:nth-child(1) {
          max-width: 150px;
        }

        &:nth-child(2) {
          left: 150px;
        }

        &:nth-child(3) {
          left: 285px;
        }

        &:nth-child(4) {
          left: 330px;
        }
      }
    }

    .wrapperAccordion {
      width: 410px;
    }


    .noteWrapper {
      width: 260px;
      margin-left: 150px;
      text-align: justify;
      font-size: $ultra_small-size;

      &>span:first-child {
        color: #4a4a4a;
      }

      &>span:last-child {
        color: #9b9b9b;
      }
    }

    .textareaWrapper {
      height: 89px;
    }

    .textareaWrapper,
    .checkboxGroupWrapper {
      display: flex;
      font-size: 0;

      &>*:first-child {
        width: 150px;
      }
    }
  }
}