//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.instruction {
  border: 1px solid $grey-color-2;
  width: 1500px;
  margin: 0 auto;
  //font-size: $middle-size;
  h3 {
    text-align: center;
    //border-bottom: 1px solid $grey-color-2;
    margin: 0;
    padding: 15px 0 3px;
  }
  ol {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 10px 20px;
    height: 400px;

    li {
      padding: 2px 0;
      margin-left: 15px;
      width: 700px;
      list-style-type: decimal;
    }
  }
}
