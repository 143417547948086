//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.Select {
  position: relative;
  cursor: pointer;

  .input {
    position: relative;
    display: flex;
    align-items: center;
    height: 38px;

    background-color: #f5f5f5;
    border: 1px solid #d8d8d8;
    border-radius: 2px;
    &.disabled {
      cursor: not-allowed;
    }
    &.borderless {
      border: 1px solid transparent;
      border-radius: 0;
    }
    &.transparent {
      background-color: transparent;
    }

    .value {
      flex: 1;
      padding: 0 10px;
      height: 36px;
      line-height: 36px;  
      font-size: $middle-size;
      color: $primary-color;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.empty {
        color: #9b9b9b;
      }
    }
    .wrapperIcon{
      display: flex;
      align-items: center;
      justify-content: end;
      width: 40px;
    }
    .xIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12px;
      height: 12px;
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0;
      margin-right: 10px;
      img {
        width: 6px;
        height: 6px;
      }
    }
  }

  .options {
    position: absolute;
    left: 0;
    z-index: 3;
    width: 100%;
    min-width: 100px;
    overflow-y: auto;
    background-color: #f1efee;
    box-shadow: 0px 2px 23px #e4e6e9;
    .option {
      height: 38px;
      background-color: white;
      margin-bottom: 1px;
      .text {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 13px;
        color: #4a4a4a;
        white-space: nowrap;
        padding: 0 12px;
      }

      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        background-color: #f5f4f3;
      }
    }
    .error {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 38px;
      background-color: white;
      font-size: 12px;
      color: #D0021B;
    }
  }
}

.wrap{
  position: relative;
  height: 36px;
}

.send{
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  color: #9b9b9b;
  cursor: pointer;
  &:focus,&:hover{
    color: #EA8226;
  }
}

.img{
  width: 100%;
  height: 14px;
}