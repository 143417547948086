//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.wrapper {
  border: 1px solid #d8d8d8;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  height: 38px;
  background: #FAFAFA;
}
.title {
  padding-left: 10px;
  color: #4A4A4A;
  font-size: 14px;
}
.buttons {
  display: flex;
  align-items: center;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  font-size: 13px;
  color: #9B9B9B;
  cursor: pointer;

  img {
    width: 6px;
    height: 6px;
    margin-right: 10px;
  }
}

.contentWrapper {
  position: relative;
  min-height: 100px;
  padding: 15px 40px; 
}

.line {
  border-top: 1px solid #d8d8d8;
  margin: 20px 0; 
}

.errorWrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error {
  font-size: 12px;
  color: #D0021B;
}

.spinnerWrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(74, 74, 74, 0.3);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 40px; 
  background-color: #fafafa;
}

.buttonCancel {
  width: 80px;
  height: 30px;  
  background: #ffffff;
  border: 1px solid #d6d6d6;
  font-size: 13px;
  text-transform: uppercase;
  color: #292929;
}

// .buttonDelete {
//   width: 80px;
//   height: 30px; 
//   font-size: 12px;
//   color: #9b9b9b;
//   background: transparent;
//   border: none;
// }
.buttonSave {
  width: 80px;
  height: 30px; 
  font-size: 13px;
  text-transform: uppercase;
  color: white;
  background-color: #4A4A4A;
  border: none;
}

