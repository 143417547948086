//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.CheckboxInput {
  display: flex;
  align-items: center;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
  margin: 0 -5px;
  & > * {
    margin: 0 5px;
  }

  .input {
    width: 12px;
    height: 12px;
    position: relative;
    background-color: #efeeee;
    border: 1px solid #d8d8d8;
    border-radius: 2px;
    .checkmark {
      position: absolute;
      left: 4px;
      top: -4px;
      width: 6px;
      height: 12px;
      border: solid $additional-color;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  .label {
    color: #9B9B9B;
    font-size: 13px;
    display: flex;
    align-items: center;
  }

  .message {
    color: $additional-color;
    display: inline-block;
    margin-left: 5px;
  }
}
