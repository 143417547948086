//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.page {
  background: #ffffff;
  width: 100%;
  position: relative;
  height: 100%;
}

.wrapper {
}

.manager_Wrapper {
  width: 1100px;
  padding: 0 0 40px 0;
  flex: 0 0!important;
}
.instruction {
  font-size: $middle-size;
}
.title {
  color: #4A4A4A;
  font-size: 24px;
  font-weight: 400;
  margin: 0;
  text-align: center;
}

.little_title {
  font-size: 12px;
  color: #9B9B9B;
  display: inline-block;
  margin: 10px 0 8px;
}

.first_block {
  margin-left: 16px;
}

.cross {
  width: 11px;
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

.buttons {
  font-size: 14px;
  display: grid;
  grid-template-columns: max-content;
  justify-content: center;
  margin-right: 41px;
  grid-column-gap: 8px;
  button {
    padding: 10px 16px;
    outline: none;
    border: 0;
  }
}
.button_cancel {
  background: #F1EFEE;
  color: #9B9B9B;
}

.button_save {
  background: #4A4A4A;
  color: #ffffff;
}
