//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


ul,
li {
	margin: 0;
	padding: 0;
	list-style: none;
}

h1,
h2,
h3,
h4,
p {
	margin-top: 0;
	margin-bottom: 0;
}

.wrapper {
	display: flex;
	flex-direction: column;

	& > * {
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	height: 100%;
}

.titleWrapper {
	display: flex;
	align-items: center;
	height: 42px;

	& > *:first-child {
		margin-right: 20px;
	}
}

.titleReply {
	color: #9b9b9b;
}

.textareaWrapper {
	width: 900px;
}

.buttonsWrapper {
	display: grid;
	grid-template-columns: repeat(2, max-content);
	justify-content: center;
	margin: 20px 0;
	grid-column-gap: 10px;
}

.buttonSecondary {
	width: 80px;
	height: 30px;
	font-size: 13px;
	background: #ffffff;
	border: 1px solid #d8d8d8;
	color: #292929;
}

.buttonMain {
	width: 80px;
	height: 30px;
	color: white;
	background-color: #4a4a4a;
	border: none;
	font-size: 13px;
}

.notesWrapper {
	display: flex;
	flex-direction: column;
	max-width: 900px;
}

.notesItem {
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	& + .notesItem {
		margin-top: 5px;
	}
}

.noteWrapper {
	display: flex;
	background-color: #f5f5f5;
	margin-bottom: 10px;
}

.left {
	width: 60px;
	min-width: 60px;
	padding: 10px;
}

.right {
	flex: 1 1;
	padding: 10px;
}

.photo {
	width: 40px;
	height: 40px;
	background-color: #ebebeb;
	border-radius: 50%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.author {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
	color: #9b9b9b;
	font-size: 13px;
	div + div{
		margin-left: 3px;
	}
}

.text {
	color: #292929;
	margin-bottom: 2px;
	font-size: 13px;
	white-space: pre-wrap;
}

.message {
	display: flex;
	align-items: center;
	height: 42px;
	font-size: 13px;
	color: #9b9b9b;
}

.actions {
	display: grid;
	grid-template-columns: repeat(3, max-content);
	grid-column-gap: 10px;

	div {
		cursor: pointer;
	}
}

.edit {
	color: $additional-color;
}

.delete {
	color: $negative-color;
}

.reply {
	padding: 0 5px;
	color: #292929;
	border: 1px solid #292929;
	background-color: #ffffff;
}

.historyOfChangesBtn {
	cursor: pointer;
	text-decoration: underline;
}

.historyOfChanges {
	position: relative;
	margin-right: auto;
}

.replyHeder {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
}

.loadMoreReplyBtn {
	font-size: 16px;
	text-decoration: underline;
	color: #9b9b9b;
	border: none;
	background-color: transparent;
	margin: 0 auto;
}

.noteInfo{
	display: flex;
	flex-direction: row;
	align-items: center;
  flex-wrap: wrap;
	p+p{
		margin-left:2px;
	}
}

.wrapperBtn{
	button{
		margin-left: 360px;
	}
}