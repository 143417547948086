//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.wrapper {
  border: 1px solid #d8d8d8;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  background: #fafafa;
  .title {
    padding-left: 10px;
    color: #4a4a4a;
    font-size: 14px;
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 38px;
    font-size: 13px;
    color: #9b9b9b;
    cursor: pointer;
  }
}

.main {
  position: relative;
  min-height: 100px;
  padding: 15px 40px;
  
  & > *:not(:last-child)::after {
    content: "";
    margin: 7px 0;
    border-top: 1px solid #d8d8d8;
    display: block;
  }
  
  .row {
    .inputs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 52px;
    }
    .buttonWrapper {
      display: flex;
      justify-content: center;
   
      .button {
        margin: auto;
        width: 80px;
        height: 30px;
        color: #9B9B9B;
        background: transparent;
        border: 1px solid transparent;
        font-size: 13px;
      }
    }
  }
}

.line {
  border-top: 1px solid #d8d8d8;
  margin: 20px 0;
}

.errorWrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error {
  font-size: 12px;
  color: #d0021b;
}
