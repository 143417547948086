//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.SecurityForm {
  width: 450px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: white;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 2px 25px rgb(208 201 201 / 50%);
  input{
    padding: 0 10px;
    height: 40px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 20px;
    &.Invalid {
      border-color: #D0021B;
    }
  }
}

.Info {
  font-size: 20px;
  width: 349px;
  margin: 0;
}

.SubmitBtn,
.CancelBtn {
  margin: 0 auto;
  width: 260px;
  height: 50px;
  font-size: 20px;
  background-color: #fff;
  color: #ea8226;
  border: 1px solid #ea8226;
  border-radius: 6px;
}
.CancelBtn{
  color: #a29f9f;
  border: 1px solid #a29f9f;
}

.Error {
  position: absolute;
  font-size: 11px;
  color: #d0021b;
}

.Wrapper {
  position: relative;
  margin-bottom: 20px;
}
.WrapperBtn{
  display: flex;
  button + button{
    margin-left: 20px;
  }
}